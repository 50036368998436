<template>
  <v-card
    :style="smUp ? 'background-color: transparent; padding-top: 20px; margin-bottom: 50px':''"
    flat
  >
    <v-layout
      :style="smUp ? 'max-width: 85%; margin-top: 2.5%': 'margin-top: 2.5%'"
      wrap
      row
      :justify-center="!smUp"
      :justify-start="smUp"
    >
      <v-flex xs12 sm6>
        <v-layout row :justify-center="!smUp" :justify-start="smUp">
          <p v-text="'Solicitud de clientes'" class="view_title_text" />
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-container>
        <v-card
          :style="smUp ? 'margin-left: -5%;background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;border-radius: 15px;opacity: 1;':''"
        >
          <v-layout row style="margin: 0;">
            <v-flex sm5 pa-3>
              <v-text-field
                v-model="user_search"
                style="margin: 25px"
                height="50px"
                color="secondary"
                
                hide-details
                :append-icon="'mdi-magnify'"
                label="Búsqueda general"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-container>
    </v-layout>
    <v-layout>
      <v-container>
        <!-- Start table container -->
        <div v-if="!bLoading">
          <OrderTable :items="orders_list" @update="getOrders" />
                      <v-pagination
              style="margin-left:-19%"
              v-model="current_page"
              :length="num_pages"
            ></v-pagination>
        </div>
        
        <!-- Loading animation -->
        <v-card flat style="height: 100%; width: 100%; background-color: transparent" v-else>
          <v-layout :style="smUp ? 'max-width: 90%; margin-top: 10%':''" row justify-center>
            <v-progress-circular indeterminate rotate color="primary" size="100" width="1" />
          </v-layout>
        </v-card>
        <!-- Loading animation -->
        <!-- End table container -->
        <div
          style="position: fixed; bottom: 0;text-align: center; justify-content: center; justify-items: center; width:69%; margin-bottom:20px;"
        >
          <span style="color: black; font-size: 16px;">
            Powered by
            <a
              style="color: black;"
              href="https://sofex.com.mx/"
              target="_blank"
            >Sofex Technologies</a>
            <img
              style="margin-left: 8px;position: relative; top: 6px;height: 25px; width: 25px;"
              src="/static/sofexlogo.png"
              alt
            />
          </span>
        </div>
      </v-container>
    </v-layout>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    menu13: false,
    menu14: false,
    dateFormattedInicial: null,
    dateInicial: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormattedFinal: null,
    dateFinal: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    orders_list: [],
    orderBy: null,
    orderList: [
      { text: "Carrito", value: 0 },
      { text: "Pagado", value: 1 },
      { text: "Enviado", value: 2 },
      { text: "Entregado", value: 3 },
    ],
    user_search: "",
    current_page: 1,
    num_pages: 0,
    total_users: 0,
    clients_list: [],
    add_client: false,
    bLoading: false,
  }),
  watch: {
    dateInicial(val) {
      this.dateFormattedInicial = this.formatDate(this.dateInicial);
    },
    dateFormattedInicial(val) {
      this.dateFormattedInicial = this.formatDate(this.dateInicial);
      this.getFilteredOrders();
    },
    dateFinal(val) {
      this.dateFormattedFinal = this.formatDate(this.dateFinal);
    },
    dateFormattedFinal(val) {
      this.dateFormattedFinal = this.formatDate(this.dateFinal);
      this.getFilteredOrders();
    },
    current_page: function () {
      if (this.client_search && !this.bLoading) {
        this.get_clients_search();
      } else if (!this.bLoading) {
        this.get_clients();
      }
    },
    user_search: _.debounce(function () {
      if (this.client_search && !this.bLoading) {
        this.getOrders();
      } else if (!this.bLoading) {
        this.getOrders();
      }
    }, 500),
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getFilteredOrders() {
      let that = this;

      var config = {
        method: "get",
        url: `${uri}/api/contacts?bSeen=false&iPageNumber=1`,
        headers: {
          Authorization: that.usr_token,
        },
      };

      db(config)
        .then((resp) => {
          that.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          console.log("info")
          console.log(resp.data)
          that.num_pages = resp.data.iNumPages;
          that.orders_list = resp.data.orders.results;
        })
        .catch((err) => {
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },

    getOrders() {
      let that = this;
      if(this.user_search != null){
        var config = {
          method: "get",
          url: `${uri}api/contacts?bSeen=false&iPageNumber=${this.current_page}&sSearchTerm=${this.user_search}`,
          headers: {
            Authorization: this.usr_token,
          },
        };
      }else{
              var config = {
        method: "get",
        url: `${uri}api/contacts?bSeen=false&iPageNumber=${this.current_page}&iPageSize=20`,
        headers: {
          Authorization: this.usr_token,
        },
      };
      }


      db(config)
        .then((resp) => {
          that.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          console.log(resp.data.contacts)
          that.num_pages = resp.data.numPages;
          that.orders_list = resp.data.contacts;
        })
        .catch((err) => {
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    get_clients() {
      this.bLoading = true;
      db.get(`${uri}/api/users`, {
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: this.usr_token,
        },
        params: {
          iPage: this.current_page,
          iPageSize: 15,
          iRole: 2,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          this.num_pages = resp.data.iTotalPages;
          this.total_users = resp.data.iTotalUsers;
          this.clients_list = resp.data.users;
        })
        .catch((err) => {
          this.bLoading = false;
          this.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
    get_clients_search() {
      let that = this;

      var config = {
        method: "get",
        url: `https://api.crua.mx/orders?iPageNumber=${that.current_page}&iPageSize=20&query=${that.user_search}`,
        headers: {
          Authorization: that.usr_token,
        },
      };

      db(config)
        .then((resp) => {
          that.$store.commit("toggle_alert", {
            color: "success",
            text: resp.data.message,
          });
          that.num_pages = resp.data.iNumPages;
          that.orders_list = resp.data.orders.results;
          console.log(that.orders_list);
        })
        .catch((err) => {
          that.$store.commit("toggle_alert", {
            color: "fail",
            text: err.response.data.message,
          });
        });
    },
  },
  computed: {
    smUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    usr_token() {
      return "Bearer " + this.$store.state.token;
    },
  },
  components: {
        OrderTable: () =>
      import("@/components/sections/Admin/Orders/OrderTable"),
  },
};
</script>
<style scoped>
.total_user_text {
  font-family: "Open sans", sans-serif;
  font-size: 15px;
  margin-right: 10%;
}
.total_user_text_mobile {
  max-width: 90%;
  text-align: center;
  font-family: "Open sans", sans-serif;
  font-size: 10px;
}
.search_user {
  margin-right: 10%;
}
.search_user_mobile {
  max-width: 80%;
}
</style>